// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--font-family-primary: \"Roboto\", sans-serif;--font-size-normal: 40px;--font-size-large: 60px}*{color:#fff;font-family:var(--font-family-primary);text-transform:uppercase;font-size:var(--font-size-normal)}", "",{"version":3,"sources":["webpack://./styles/normalize.scss","webpack://./styles/variables.scss"],"names":[],"mappings":"AAAQ,MCCN,2CAAA,CAEA,wBAAA,CACA,uBAAA,CDFF,EACE,UAAA,CACA,sCAAA,CACA,wBAAA,CACA,iCAAA","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap\");\n\n* {\n  color: white;\n  font-family: var(--font-family-primary);\n  text-transform: uppercase;\n  font-size: var(--font-size-normal);\n}\n",":root {\n  --font-family-primary: \"Roboto\", sans-serif;\n\n  --font-size-normal: 40px;\n  --font-size-large: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
