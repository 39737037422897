// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ":root{--font-family-primary: \"Roboto\", sans-serif;--font-size-normal: 40px;--font-size-large: 60px}.ui--pnESU{display:grid;align-content:center;justify-content:center;position:fixed;top:0;left:0;width:100%;height:100%;pointer-events:none}.ui--pnESU .container--iGeKD{transform-origin:center center;position:relative}", "",{"version":3,"sources":["webpack://./app.module.scss","webpack://./styles/variables.scss"],"names":[],"mappings":"AAAQ,MCCN,2CAAA,CAEA,wBAAA,CACA,uBAAA,CAAA,WDCA,YAAA,CACA,oBAAA,CACA,sBAAA,CACA,cAAA,CACA,KAAA,CACA,MAAA,CACA,UAAA,CACA,WAAA,CACA,mBAAA,CAEA,6BACE,8BAAA,CACA,iBAAA","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');\n\n@import \"./styles/variables.scss\";\n\n.ui {\n  display: grid;\n  align-content: center;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  pointer-events: none;\n\n  .container {\n    transform-origin: center center;\n    position: relative;\n  }\n}\n",":root {\n  --font-family-primary: \"Roboto\", sans-serif;\n\n  --font-size-normal: 40px;\n  --font-size-large: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ui": "ui--pnESU",
	"container": "container--iGeKD"
};
export default ___CSS_LOADER_EXPORT___;
